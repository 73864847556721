import React, {useCallback, useMemo} from "react";
import {FeatureHeadingWrapper, FeatureTitle} from "./FeatureHeading-styled";
import useFeature from "../../../../../hooks/useFeature";
import {CloseIcon} from "../../../../Icons";
import {view} from "../../../../../utils/API";

const ShareIcon = ({...props}) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18.377 22C17.5436 22 16.8353 21.7083 16.252 21.125C15.6686 20.5417 15.377 19.8333 15.377 19C15.377 18.8833 15.3853 18.7623 15.402 18.637C15.4186 18.5117 15.4436 18.3993 15.477 18.3L8.42695 14.2C8.14362 14.45 7.82695 14.646 7.47695 14.788C7.12695 14.93 6.76029 15.0007 6.37695 15C5.54362 15 4.83529 14.7083 4.25195 14.125C3.66862 13.5417 3.37695 12.8333 3.37695 12C3.37695 11.1667 3.66862 10.4583 4.25195 9.875C4.83529 9.29167 5.54362 9 6.37695 9C6.76029 9 7.12695 9.071 7.47695 9.213C7.82695 9.355 8.14362 9.55067 8.42695 9.8L15.477 5.7C15.4436 5.6 15.4186 5.48767 15.402 5.363C15.3853 5.23833 15.377 5.11733 15.377 5C15.377 4.16667 15.6686 3.45833 16.252 2.875C16.8353 2.29167 17.5436 2 18.377 2C19.2103 2 19.9186 2.29167 20.502 2.875C21.0853 3.45833 21.377 4.16667 21.377 5C21.377 5.83333 21.0853 6.54167 20.502 7.125C19.9186 7.70833 19.2103 8 18.377 8C17.9936 8 17.627 7.92933 17.277 7.788C16.927 7.64667 16.6103 7.45067 16.327 7.2L9.27695 11.3C9.31029 11.4 9.33529 11.5127 9.35195 11.638C9.36862 11.7633 9.37695 11.884 9.37695 12C9.37695 12.116 9.36862 12.237 9.35195 12.363C9.33529 12.489 9.31029 12.6013 9.27695 12.7L16.327 16.8C16.6103 16.55 16.927 16.3543 17.277 16.213C17.627 16.0717 17.9936 16.0007 18.377 16C19.2103 16 19.9186 16.2917 20.502 16.875C21.0853 17.4583 21.377 18.1667 21.377 19C21.377 19.8333 21.0853 20.5417 20.502 21.125C19.9186 21.7083 19.2103 22 18.377 22Z" fill="#8C9BA5"/>
  </svg>
)

const EditIcon = ({...props})=>(
  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="200px" width="200px" xmlns="http://www.w3.org/2000/svg" {...props}><path d="M17.263 2.177a1.75 1.75 0 0 1 2.474 0l2.586 2.586a1.75 1.75 0 0 1 0 2.474L19.53 10.03l-.012.013L8.69 20.378a1.753 1.753 0 0 1-.699.409l-5.523 1.68a.748.748 0 0 1-.747-.188.748.748 0 0 1-.188-.747l1.673-5.5a1.75 1.75 0 0 1 .466-.756L14.476 4.963ZM4.708 16.361a.26.26 0 0 0-.067.108l-1.264 4.154 4.177-1.271a.253.253 0 0 0 .1-.059l10.273-9.806-2.94-2.939-10.279 9.813ZM19 8.44l2.263-2.262a.25.25 0 0 0 0-.354l-2.586-2.586a.25.25 0 0 0-.354 0L16.061 5.5Z" fill="#8C9BA5"></path></svg>
)

const FeatureHeading = ({feature, t, handleShare, actionButtons, expanded, isPopup = false}) => {
  const {
    dateTimeAgo,
    getImage,
    title
  } = useFeature(feature, t)
  
  const handleClose = useCallback(()=>{
    view.closePopup();
  },[]);
  
  const handleEdit = useCallback(()=>{
    const editIndex = actionButtons.findIndex(b=>b.id === 'edit-action');
    if (editIndex > -1) {
      view.popup.triggerAction(editIndex);
    }
    
  },[actionButtons])
  
  const image = useMemo(()=>{return getImage()},[getImage])
  
  const subtractWidth = useMemo(()=>{
    const hasEdit = actionButtons.some(b=>b.id === 'edit-action');
    const hasShare = actionButtons.some(btn=>btn.id === 'share');
    let padding = 40;
    if (hasEdit){
      padding += 36
    }
    
    if (hasShare){
      padding += 36
    }
    return padding
  },[actionButtons])
  
  return (
    <FeatureHeadingWrapper>
      <div
        style={{
          display:'flex',
          flexDirection:'row',
          justifyContent:'space-between',
          gap:2,
        }}>
        <div style={{display:'flex', gap:4, flex:1, maxWidth: `calc(100% - ${subtractWidth}px)`,}}>
          {image && <div style={{height: 38, display: "flex", alignItems: "center", width: 38, minWidth:38}}>
            {getImage()}
          </div>}
          <FeatureTitle isPopup={isPopup}>
            <p className={`${!expanded || isPopup ? "text-elipsis" : ''}`}>
              {title}
            </p>
          </FeatureTitle>
        </div>
        <div
          style={{
            display:'flex',
            alignItems:'center',
            gap:8,
            height:38
          }}>
          {
            actionButtons.some(btn=>btn.id === "edit-action") && (
              <div
                title={t('screen.popup.actions.edit-action')}
                style={{
                  borderRadius:100,
                  border:'1px solid #eee',
                  width:28,
                  height:28,
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  cursor: 'pointer'
                }}
                onClick={handleEdit}>
                <EditIcon width="18" height="18" />
              </div>
            )
          }
          {
           actionButtons.some(btn=>btn.id === 'share') && (
              <div
                title={t('screen.popup.actions.share')}
                style={{
                  borderRadius:100,
                  border:'1px solid #eee',
                  width:28,
                  height:28,
                  display:'flex',
                  alignItems:'center',
                  justifyContent:'center',
                  cursor: 'pointer'
                }}
                onClick={handleShare}>
                <ShareIcon width="20" height="20" />
              </div>
            )
          }
          <div
            title={t('screen.popup.actions.close')}
            style={{
              borderRadius:100,
              border:'1px solid #eee',
              width:28,
              height:28,
              display:'flex',
              alignItems:'center',
              justifyContent:'center',
              cursor: 'pointer'
            }}
            onClick={handleClose}>
            <CloseIcon width="20" height="20" />
          </div>
        </div>
        {/*{getImage()}*/}
      </div>
      {/*<div style={{display:'flex', gap:10, alignItems:'center', justifyContent:'center'}}>*/}
      {/*  <button*/}
      {/*    onClick={handleShare}*/}
      {/*    style={{display:'flex', alignItems:'center', gap:4, padding: '4px 8px', border:'1px solid #eee', borderRadius:100, background:'#eee'}}>*/}
      {/*    <span style={{fontSize: 12, color:'#8C9BA5'}}>Share</span>*/}
      {/*    <ShareIcon width="16" height="16" />*/}
      {/*  </button>*/}
      {/*  <div*/}
      {/*    style={{display:'flex', alignItems:'center', gap:4, padding: '4px 8px', border:'1px solid #eee', borderRadius:100, background:'#eee'}}>*/}
      {/*    <span style={{fontSize: 12, color:'#8C9BA5'}}>Suggest an update</span>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </FeatureHeadingWrapper>
  );
};

export default FeatureHeading;
