import {StyledProgress} from "../../App/App-styled";
import React, {useEffect, useState} from "react";
import {timeAgo} from "../../../utils/helper";
import {useTranslation} from "react-i18next";
import {StyledHeader, StyledHeaderDate, StyledHeaderTitle} from "../Dashboard-styled";

const DashboardHeader = ({title, updateDate, isLoading, config, icon, children}) => {
  const [time, setTime] = useState('');
  const {t, i18n} = useTranslation('common');
  
  let timeout;
  useEffect(()=>{
    if (!updateDate) return;
    const handleTimeChange = (refreshedTime)=>{
      if (refreshedTime === 'now') {
        setTime(t('screen.widget.common.refreshedNow'));
      } else {
        setTime(t('screen.widget.common.refreshed') + ' ' + refreshedTime);
      }
    }
    
    const refreshedTime = timeAgo(updateDate, i18n.language);
    handleTimeChange(refreshedTime);
    clearTimeout(timeout);
    timeout = setTimeout(()=>{
      const refreshedTime = timeAgo(updateDate, i18n.language);
      handleTimeChange(refreshedTime);
    },1000 * 60)
    return ()=>{
      clearTimeout(timeout)
    }
  },[updateDate])
  
  return (
    <StyledHeader showBorder={children || title}>
      {title && <StyledHeaderTitle>
        <p>{title} </p>
        {icon}
      </StyledHeaderTitle>}
      <StyledHeaderDate
        style={{
          opacity: !!time ? 1 : 0,
        }}
      >
        {time ? `${time}` : ''}
      </StyledHeaderDate>
      {children}
      {
        isLoading && (
          <div
            style={{
              position:'absolute',
              bottom:0,
              left:0,
              width:'100%'
            }}>
            <StyledProgress type="indeterminate" />
          </div>
        )
      }
    </StyledHeader>
  );
};

export default DashboardHeader;
