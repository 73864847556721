import styled from 'styled-components';

export const StyledPopupWrapper = styled.div`
 * {
   line-height: normal;
 }
  
  max-height: ${({isPopup})=>isPopup ? '550px' : ''};
  overflow: ${({isPopup})=>isPopup ? 'auto' : ''};
`

export const StyledButtonWrapper = styled.div`
  margin-top: 10px;
  position: sticky;
  top: -9px;
  background: #fff;
  padding: 0 0 10px 0;
  overflow: hidden;
  transition: all 0.5s;
  clip-path: inset(0px 0px -3px 0px);
  z-index: 1;
  
  & > div {
    padding: 10px 10px 10px 10px;
    display: flex;
    justify-content: space-around;
  }

  @media screen and (min-width: 769px) and (max-width: 1210px) {
    top: 0px;
  }
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
  background:#FFFFFF;
  border: 1px solid #575757;
  transition:all 0.3s;
  min-width: 70px;

  :hover {
    background: #5757571A;
  }
  padding: 10px;
  border-radius: 100px;
  
  span {
    font-size: 12px;
    color: #575757;
    font-weight: 500;
  }
  
  svg {
    width: 12px;
    height: 12px;
  }
`

export const StyledField = styled.div`
  transition: all 0.3s;
  
  p {
    display: flex;
    text-align: ${({isArabic})=> isArabic ? 'right' : ''};
    font-size: ${({isPopup})=> isPopup ? '12px' : '14px'};
    color: #6c6b6b;
    line-height: normal;
    margin: 0 !important;
    
    span {
      font-size: ${({isPopup})=> isPopup ? '12px' : '14px'};
    }
    
    a {
      word-break: ${({isArabic})=> isArabic ? 'break-word' : ''} !important;
    }
    
    span{
      span:not(:first-child) {
        color: #000000;
        font-weight: 600;
      }
    }
  }
`

export const StyledTranslationText = styled.span`
  color: #000;
  font-size:10px;
  display:block;
  align-items:center;
  padding-top:4px;
  cursor:pointer;
  font-weight:400;
  width: fit-content;
  
  span {
    font-size:10px;
    font-weight:400;
  }
`

export const StyledInformation = styled.p`
  color: #6c6b6b;
  line-height: normal;
  margin: 0;
  font-size: 12px !important;
  font-style: italic;
  display: block;
  span {
    font-weight: 600;
  }
`

export const LCATableWrapper = styled.div`
  margin-top: 10px;
  overflow: hidden;
  ul {
    list-style-type: none;
    padding-left: 0;
    text-align: left;
    font-size: 14px;
    color: #000;
  }
  
  h1 {
    font-size: 16px;
  }
  
  h2 {
    font-size: 14px;
    color: var(--opsColor);
  }

  h3, h4, h5, h6 {
    font-size: 12px;
    color: var(--opsColor);
  }

  p, span {
    margin:0;
    font-size: 12px !important;
  }
  
  table {
    width: 100%;
  }
  table p {
    margin: 4px;
    text-align: left !important;
  }

  tr {
    display: ${({withStyles})=>withStyles ? 'grid' : 'table-row'};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    text-align: left !important;
    column-gap: 2px;
  }

  table th, table td {
    background: ${({withStyles, opsColor})=>withStyles ? opsColor+'0f' : '#F6F6F6'};
    font-size: 14px;
    text-align: left !important;
    margin-bottom: 2px;
  }
  
  table th {
    background: rgb(167,192,217);
  }
  
  .table-wrap {
    margin-bottom: 10px;
    overflow: auto;
  }
  
  th[colspan="2"], th[colspan="4"]{
    grid-column: span 2;
  }

  td[rowspan="2"]{
    grid-row: span 2;
  }
  
  img {
    width: 100%;
    height: auto;
  }
  
  .toc-macro {
    display: none;
  }
  
  br {
    display: none;
  }
`

export const StyledPopupBody = styled.div`
  position:absolute;
  height: 100%;
  width: 100%;
  overflow:auto;
  //margin-top: 64px;
  //to move scrollbar to left
  //transform: scaleX(-1);
  text-align: left;
  padding-right: 8px;
  box-sizing: border-box;
  animation: fadeIn 0.5s linear;
  user-select: ${({expand})=>expand ? 'auto' : 'none'};
  padding-top: 8px;

  --calcite-color-brand:${({opsColor})=>opsColor};
  --calcite-color-brand-hover: transparent;
  --swiper-navigation-color:${({opsColor})=>opsColor};
  --swiper-theme-color:${({opsColor})=>opsColor};

  @media screen and (max-width: 768px) {
    margin-top: 38px;
    height: ${({expand})=> expand ? '100%' : 'auto'};
  }
`

export const StyledSeeMore = styled.span`
  background: none;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 10px;
  font-style: italic;
  padding: 8px;
  color: ${({opsColor = '#575757'})=>opsColor};
  border-radius:8px;
  transition:all 0.3s;
  //
  // :hover {
  //   background: ${({opsColor = '#575757'})=>opsColor + '1A'};
  // }
`

export const StyledPopupTabs = styled.div`
  width: 100%;
  overflow: auto;
  border-bottom: 2px solid #eee;
  padding: 10px 8px 0;
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position:sticky;
  top: 56px;
  z-index: 1;
  background:#fff;
`

export const StyledPopupTab = styled.div`
  display: flex;
  position: relative;
  width: max-content;
  border-bottom: 2px solid ${({opsColor, selected})=> selected ? opsColor : 'transparent'};
  
  span {
    font-size:15px;
    font-weight:600;
    padding:0 5px;
    color: ${({opsColor})=> opsColor};
  }
`

export const StyledFieldSection = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 8px 8px 4px;
  
  ${({hasLca})=> hasLca ? `
    ::after {
      content: '';
      position: absolute;
      left: -8px;
      height: 1px;
      width: calc(100% + 8px);
      bottom: 0px;
      background: #b6b6b6;
    }
  ` : ''}
`

export const StyledFieldSectionWrapper = styled.div`
  display:flex;
  justify-content: space-between;
  direction: ${({isArabic})=> isArabic ? 'rtl' : 'ltr'};
  gap:8px;
  padding-top:6px;
  align-items:center;
  cursor: pointer;
  
  span:first-child{
    display:flex;
    gap:4px;
    align-items:center;
    font-size: ${({isPopup})=> isPopup ? '12px' : '14px'};
    color: #6c6b6b;
  }
`

export const StyledImageSlider = styled.div`
  .swiper-button-prev, .swiper-button-next {
    color: #8c9ba5;
  }

  padding: 8px;
  
  .swiper-wrapper {
    display: flex;
    align-items: flex-end;
  }

  .swiper-slide {
    width: auto;
    height: auto;
  }
  
  img {
    height: 100%;
    object-fit: contain;
    animation: fadeIn 0.5s linear;
    border-radius: 14px;
    max-width: 376px;
    max-height: 200px;
  }
 
`
