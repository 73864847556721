import {useCallback, useEffect, useMemo, useState} from "react";
import useWindowSize from "./useWindowSize";

const mobileOffset = 35;

const useDragSidebar = ({
  defaultWidth,
  ref,
  expandLimit = window.innerWidth / 2
}) => {
  const [expand, setExpand] = useState(false);
  const {width} = useWindowSize();
  
  const isMobile = useMemo(()=>{
    return width <= 768;
  },[width])
  
  useEffect(()=>{
    if (!expand && ref.current) {
      ref.current.style.flexBasis = '';
    }
  },[expand])
  
  useEffect(() => {
    if (!ref.current) return;
    if (isMobile) {
      ref.current.style = '';
      const viewDiv = document.querySelector('#viewDiv');
      viewDiv.style.height = `calc(100% - ${mobileOffset}px)`;
      viewDiv.style.transition = ``;
    } else {
      const viewDiv = document.querySelector('#viewDiv');
      viewDiv.style.height = ``;
    }
    
  },[isMobile])
  
  const onArrowClick = useCallback(()=>{
    if (isMobile) {
      setExpand(prev=>!prev);
      return;
    }
  
    const viewDiv = document.querySelector('#viewDiv');
    viewDiv.style.transition = 'all 0.5s';
    if (!expand) {
      ref.current.style.flexBasis = '';
      setExpand(prev=>!prev)
    } else {
      ref.current.style.flexBasis = '';
      setExpand(prev=>!prev)
    }
  },[isMobile, expand])
  
  const doDrag = useCallback((e)=>{
    if (!ref.current) return;
    e.preventDefault();
    ref.current.style.transition = 'none';
    const appWidth = window.innerWidth;
    const width = appWidth - e.clientX;
    const viewDiv = document.querySelector('#viewDiv');
    viewDiv.style.transition = 'none'
  
    if (width < 20) {
      ref.current.style.flexBasis = ``;
      ref.current.style.transition = 'all 0.5s';
      setExpand(false);
      return;
    }
  
    if (width < 220 && ref.current.offsetWidth >= 220) {
      ref.current.style.flexBasis = ``;
      ref.current.style.transition = 'all 0.5s';
      setExpand(false);
      stopDrag();
      return;
    }
  
    if (width > expandLimit) {
      ref.current.style.flexBasis = `${expandLimit}px`;
      return;
    }
    ref.current.style.flexBasis = `${width}px`;
    if (!expand) {
      setExpand(true);
    }
  },[expand, expandLimit]);
  
  const stopDrag = useCallback(()=>{
    if (!ref.current) return;
    const viewDiv = document.querySelector('#viewDiv');
    viewDiv.style.transition = 'all 0.5s';
    ref.current.style.transition = 'all 0.5s';
    document.documentElement.removeEventListener('mousemove', doDrag, false)
    document.documentElement.removeEventListener('mouseup', stopDrag, false)
  },[doDrag]);
  
  const initDrag = useCallback((e)=>{
    if (isMobile) return;
    // if (e.target.id !== 'sidebar-resizer') return;
    document.documentElement.addEventListener('mousemove', doDrag, false)
    document.documentElement.addEventListener('mouseup', stopDrag, false)
  },[isMobile, doDrag, stopDrag])
  
  const expandToWidth = (width) => {
    ref.current.style.flexBasis = `${width}px`;
  }
  
  return {
    expand,
    setExpand,
    initDrag,
    doDrag,
    stopDrag,
    onArrowClick,
    isMobile,
    mobileOffset,
    expandToWidth
  }
};

export default useDragSidebar;