import {useCallback, useEffect} from "react";
import {applyCurrentFilters} from "../components/Filter/ApplyFilters";
import {useDispatch, useSelector} from "react-redux";
import {setFilters_actionType} from "../redux/constants";

const useFilters = ({isActive, config}, useDebounce = true) => {
  const {filters, activeModule} = useSelector(state=>state);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (isActive) {
      applyCurrentFilters(filters[activeModule], config)
    }
  }, [activeModule])
  
  useEffect(() => {
    if (isActive && useDebounce) {
      applyFilters()
    }
  }, [filters, useDebounce])
  
  let timer
  const applyFilters = useCallback(() => {
    //Prevent creating multiple queries while user is still typing
    if (timer)
      return
    
    timer = setInterval(() => {
      clearInterval(timer)
      timer = null
      applyCurrentFilters(filters[activeModule], config)
    }, 1000)
  }, [filters])
  
  const setFilters = (f) => {
    dispatch({ type: setFilters_actionType, payload: f })
  }
  
  const setAndApplyFilters = useCallback((filters) =>{
    applyCurrentFilters(filters[activeModule], config);
    setFilters(filters);
  },[activeModule, config])
  
  return {
    filters,
    setFilters,
    setAndApplyFilters
  }
}


export default useFilters;
