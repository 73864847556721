import React, {useCallback, useEffect, useMemo, useState} from "react";
import ReactDOMServer from "react-dom/server";
import {LineSymbol} from "../components/ReportManager/symbols";
import {timeAgo} from "../utils/helper";
import {getFeatureTitle, getLayerTitle} from "../esri/custom-popup-content";
import {currAsOfDateFieldName} from "../utils/API";
import {getLayerSymbology} from "../utils/symbologies";

const useFeature = (feature, t) => {
  const [iconUrl, setIconUrl] = useState();
  const layer = feature.sourceLayer ? feature.sourceLayer : feature.layer;
  
  useEffect(() => {
    layer?.renderer?.getSymbolAsync(feature).then((symbol) => {
      if (symbol.url)
        setIconUrl(symbol.url)
      else if (symbol.type === "simple-line" && symbol.color){
        const color = symbol.color ? symbol.color.toHex() : "grey"
        setIconUrl("data:image/svg+xml;base64," +
          btoa(ReactDOMServer.renderToStaticMarkup((<LineSymbol stroke={color}/>))))
      }
    })
  }, [feature])
  
  const getImage = useCallback(()=>{
    const layer = feature.sourceLayer || feature.layer;
    
    if (iconUrl){
      return <img draggable={false} alt="feature-icon" src={iconUrl} />
    } else {
      return null;
    }
  },[iconUrl, feature])
  
  const editDateFieldName = useMemo(()=> currAsOfDateFieldName ?? feature.layer?.editFieldsInfo?.editDateField, [feature]);
  
  const formatDate = useMemo(()=> feature.attributes[editDateFieldName] ? timeAgo(new Date(feature.attributes[editDateFieldName])) : '',[editDateFieldName, feature])
  const featureTitle = useMemo(()=> {
    let title = getFeatureTitle(feature, t);
    if (!title){
      title = getLayerTitle(layer, t);
    }
    return title;
  },[feature, layer]);
  
  return {
    getImage,
    editDateFieldName,
    dateTimeAgo: formatDate,
    title: featureTitle
  }
};

export default useFeature;
