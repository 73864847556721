import LatestUpdateHeader from "../LatestUpdates/new/LatestUpdateHeader";
import DropdownFilter from "./DropdownFilter/DropdownFilter";
import {SortButton} from "./SharedInformation-styled";
import {arrowDown16, arrowUp16} from "@esri/calcite-ui-icons";
import {CustomLoader, CustomSwitch} from "../App/App-styled";
import SharedInformationCard from "./SharedInformationCard/SharedInformationCard";
import {ShowMore} from "../LatestUpdates/LatestUpdates-styled";
import {LatestUpdatesWrapper} from "../LatestUpdates/new/LatestUpdates-styled";
import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import usePullToRefresh from "../../hooks/usePoolToRefresh";
import {view} from "../../utils/API";
import {getFeatureEditDate, getOpsColor, renderCell} from "../../utils/helper";
import {getField} from "../Report/new/helpers";
import useInView from "../../hooks/useInView";
import {ConfigContext} from "../../utils/ConfigContext";
import {useTranslation} from "react-i18next";
import {loadModules} from "esri-loader";
import DashboardHeader from "../Dashboard/DashboardHeader/DashboardHeader";
import {StyledEditorBreadcrumbs} from "../Dashboard/Editor/Editor-styled";
import ProgressBar from "../Report/new/ProgressBar/ProgressBar";
import {useSelector} from "react-redux";

const SharedInformation = ({expand, handleGoBack, selectedTab}) => {
  const [features, setFeatures] = useState([]);
  const [lastDate, setLastDate] = useState(new Date());
  const [filterDropdownOptions, setFilterDropdownOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [queryLoading, setQueryLoading] = useState(false);
  const [showMoreActive, setShowMoreActive] = useState(true);
  const [showMoreLoading, setShowMoreLoading] = useState(true);
  const [showMoreOffset, setShowMoreOffset] = useState(20);
  const [sortType, setSortType] = useState('descending');
  const [extent, setExtent] = useState();
  const [count, setCount] = useState(0);
  
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation('common');
  
  const abortController = useRef({});
  const layerDefinitionWatch = useRef({});
  const sidebarRef = useRef();
  const watchHandlers = useRef([]);
  const refreshRef = useRef();
  const loaderRef = useRef();
  const {activeModule} = useSelector(state=>state);
  
  usePullToRefresh(refreshRef, ()=>{
    view.map.layers.forEach((layer) => {
      if (!layer.layerConfig?.isShownInReportManager && !layer.visible) {return}
      setCount(count=>count+1);
    })
  }, loaderRef)
  
  const visibleLayers = useRef({});
  
  useEffect(() => {
    if (watchHandlers.current.length > 0) {
      watchHandlers.current.forEach(h=>h.remove());
    }
    
    if (listeners.current.length > 0){
      listeners.current.forEach(handler=>handler.remove());
    }
    
    const layersChangeHandler = view.map.layers.on("change", (event) => {
      if (event.added)
        event.added.forEach((layer) => {

          if (!layer.layerConfig?.isShownInReportManager) {return}
          visibleLayers.current[layer.id] = layer.visible;
          const arrayVisibleLayers = Object.values(visibleLayers.current).filter(visible=>visible);
          // setShow(arrayVisibleLayers.length > 0)
          
          setCount(count=>count+1);
          const editHandler = layer.on("edits", () => {
            setCount(count=>count+1);
          })
          const refreshHandler = layer.on("refresh", () => {
            setCount(count=>count+1);
          })
          const visibleHandler = layer.watch("visible", (visible) => {
            visibleLayers.current[layer.id] = visible
            const arrayVisibleLayers = Object.values(visibleLayers.current).filter(visible=>visible);
            // setShow(arrayVisibleLayers.length > 0)
            
            if (visible){
              setCount(count=>count+1);
            } else {
              if (Array.isArray(abortController.current[layer.id])) {
                abortController.current[layer.id].forEach(controller=>controller.abort());
              }
              layerDefinitionWatch.current[layer.id]?.remove();
            }
          })
          watchHandlers.current.push(editHandler, refreshHandler, visibleHandler)
        })
    })
    watchHandlers.current.push(layersChangeHandler)
    
    view.map.layers.forEach((layer) => {
      if (!layer.layerConfig?.isShownInReportManager) {return}
      visibleLayers.current[layer.id] = layer.visible;
      const arrayVisibleLayers = Object.values(visibleLayers.current).filter(visible=>visible);
      // setShow(arrayVisibleLayers.length > 0)
      
      setCount(count=>count+1);
      
      //Update page when edit or refresh events occur:
      const editHandler = layer.on("edits", () => {
        setCount(count=>count+1);
      })
      const refreshHandler = layer.on("refresh", () => {
        setCount(count=>count+1);
      })
      const visibleHandler = layer.watch("visible", (visible) => {
        visibleLayers.current[layer.id] = visible
        const arrayVisibleLayers = Object.values(visibleLayers.current).filter(visible=>visible);
        
        // setShow(arrayVisibleLayers.length > 0)
        
        if (visible) {
          setCount(count=>count+1);
        } else {
          abortController.current[layer.id].forEach(controller=>controller.abort());
          layerDefinitionWatch.current[layer.id]?.remove();
        }
      })
      watchHandlers.current.push(editHandler, refreshHandler, visibleHandler)
    })
    
    return () => {
      watchHandlers.current.forEach(h=>h.remove());
    }
  }, [selectedTab]);
  
  const compareFeatures = (a, b, sort = 'descending') => {
    const editDateA = getFeatureEditDate(a)
    if (!editDateA)
      return 1
    
    
    const editDateB = getFeatureEditDate(b)
    if (!editDateB)
      return 1

    if (sort === 'descending') {
      return (editDateA > editDateB) ? -1 : 1
    } else {
      return (editDateA < editDateB) ? -1 : 1
    }
  }
  
  const listeners = useRef([]);
  const timers = useRef();
  useEffect(()=>{
    const layers = [];
    clearTimeout(timers.current);
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) =>{
      timers.current = setTimeout(()=>{
        view.map.layers.forEach((layer)=>{
          if (visibleLayers.current[layer.id]) {
            processLayer(layer);
            layers.push(layer)
          }
        })
  
        if (extent) {
          const stationary = reactiveUtils.when(() => !!view?.stationary, ()=>{
            if (!extent) return;
            layers.forEach(layer=>{
              processLayer(layer);
            })
          }, {initial:false})
          listeners.current.push(stationary);
        } else {
          listeners.current?.forEach(handler=>handler.remove());
        }
      },250)
    })
    
  },[count, extent])
  
  const watchTimer = useRef({});
  const processLayer = (layer) =>{
    if (!layer.visible) return;
    if (layerDefinitionWatch.current[layer.id]) {
      layerDefinitionWatch.current[layer.id].remove();
      layerDefinitionWatch.current[layer.id] = undefined;
    }
    
    setQueryLoading(true);
    layer.load().then(l=>{
      queryLayer(layer);
      layerDefinitionWatch.current[layer.id] = layer.watch("definitionExpression", () => {
        if (layer.visible){
          clearTimeout(watchTimer.current[layer.id])
          watchTimer.current[layer.id] = setTimeout(()=>{
            queryLayer(layer)
          },500)
        }
      })
    })
  }
  
  const queryLayer = async (layer) =>{
    setQueryLoading(true);
    try {
      if (abortController.current[layer.id]?.length > 0) {
        abortController.current[layer.id].forEach(controller=>{
          controller.abort();
        })
        abortController.current[layer.id] = [];
      }
      
      setLastDate(new Date());
      const query = layer.createQuery();
      
      if (extent) {
        query.geometry = view.extent
      }
      
      // query.returnGeometry = true;
      abortController.current[layer.id] = [];
      abortController.current[layer.id].push(new AbortController());
      
      const res = await layer.queryFeatures(query, {signal:abortController.current[layer.id][0].signal});
      
      const oidField = layer.objectIdField
      const objectIds = res.features.map((feature) => feature.attributes[oidField])
      if (objectIds.length > 0) {
        abortController.current[layer.id].push(new AbortController());
        const response = await layer.queryAttachments({objectIds: objectIds}, {signal:abortController.current[layer.id][1].signal});
        
        response && Object.keys(response).forEach((oid) => {
          // Each object might have different attachments
          const objectAtts = response[oid];
          res.features.forEach(feature=>{
            if (feature.attributes[oidField].toString() === oid) {
              feature.attachments = objectAtts
            }
          })
        })
      }
      
      setFeatures(res.features);
      const field = layer.fields.find(field=>field.name === 'currvalidationfield');
      
      const options = getField(layer, 'currvalidationfield').map(codedValue=>({
        name: renderCell(field, codedValue.code, t, config),
        code: codedValue.code,
        fieldName: 'currvalidationfield'
      }))

      setFilterDropdownOptions(options);
      setQueryLoading(false);
      setShowMoreActive(true);
      setShowMoreLoading(false);
    } catch (err){
      if (err.name === 'AbortError') return;
      setQueryLoading(false);
    }
  }
  
  const handleSort = useCallback(()=>{
    const sort = sortType === 'descending' ? 'ascending' : 'descending';
    setSortType(sort);
  },[sortType])
  
  const timeout = useRef(0);
  
  const onShowMoreClicked = () => {
    if (!showMoreActive || timeout) return;
    if (features.length <= showMoreOffset) {
      setShowMoreActive(false);
      return;
    }
    
    setShowMoreLoading(true);
    timeout.current = setTimeout(()=>{
      setShowMoreOffset(prev=> prev + 20)
      setShowMoreLoading(false);
    },350)
  }
  
  const featuresToShow = useMemo(()=>{
    const newFeatures = features.filter(feat=>selectedOptions.length > 0 ? selectedOptions.includes(feat.attributes['currvalidationfield']): true);
    
    if (newFeatures.length > showMoreOffset) {
      return newFeatures
        .sort((a,b)=>compareFeatures(a,b, sortType))
        // .slice(0, showMoreOffset)
    }
    return newFeatures.sort((a,b)=>compareFeatures(a,b,sortType))
  },[features, showMoreOffset, sortType, selectedOptions])
  
  const observerTarget = useRef(null);
  useInView(observerTarget, onShowMoreClicked, [showMoreActive, onShowMoreClicked])
  
  const handleSwitchChange = useCallback((e)=>{
    setExtent(e.target.checked || undefined)
  },[])
  
  const onFilterChange = useCallback((e, option)=>{
    if (selectedOptions.includes(option)){
      setSelectedOptions(prev=>prev.filter(item=>item !== option))
    } else {
      setSelectedOptions(prev=>prev.concat(option))
    }
  },[selectedOptions])
  
  const opsColor = useMemo(()=> getOpsColor(config, activeModule),[config, activeModule])
  
  return (
    <LatestUpdatesWrapper
      style={{
        paddingTop:0
      }}
      expand={expand}>
      {/*for new editor*/}
      {/*<StyledEditorBreadcrumbs hasProgress>*/}
      {/*  <ProgressBar color={config.opsColor} currentPosition={1} total={1} />*/}
      {/*  <p>Shared Information</p>*/}
      {/*  <button onClick={handleGoBack}>*/}
      {/*    <svg fillRule="evenodd" height="10" role="img" viewBox="0 0 10 10" width="10" aria-label="description"><path d="M6.32 5L10 8.68 8.68 10 5 6.32 1.32 10 0 8.68 3.68 5 0 1.32 1.32 0 5 3.68 8.68 0 10 1.32 6.32 5z"></path></svg>*/}
      {/*  </button>*/}
      {/*</StyledEditorBreadcrumbs>*/}
      <DashboardHeader
        config={config}
        updateDate={lastDate}
        queriesInProgress={queryLoading ? 1 : 0}
      >
        {!window.isSmall && <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "4px",
        }}>
          <DropdownFilter selected={selectedOptions} onChange={onFilterChange} options={filterDropdownOptions}/>
        
          <div style={{display: "flex", alignItems: "center"}}>
            <span style={{fontSize: 12, lineHeight: 1, marginRight: 6, color: "#393738"}}>{t("screen.widget.common.filterByMapExtent")}</span>
            <CustomSwitch opsColor={opsColor} checked={extent} onCalciteSwitchChange={handleSwitchChange}/>
          </div>
          <SortButton onClick={handleSort}>
            <span>{t("screen.widget.common.sortByDate")}</span>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                   fill={sortType === "descending" ? opsColor : "#cdcbcb"}>
                <path d={arrowDown16}/>
              </svg>
            </div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                   fill={sortType === "ascending" ? opsColor : "#cdcbcb"}>
                <path d={arrowUp16}/>
              </svg>
            </div>
          </SortButton>
        </div>}
      </DashboardHeader>
      <div ref={loaderRef} id="refresh" style={{opacity:0, position:'absolute', top:110, left:'50%', transform:'translateX(-50%)'}}>
        <CustomLoader opsColor={opsColor} scale="s" />
      </div>
      <div
        ref={refreshRef}
        style={{
          overflow:'auto',
          // height:'100%',
          display:'flex',
          flexDirection:'column',
          flex:1,
          // padding: '22px 0px 12px 4px',
        }}
      >
        {
          featuresToShow.map(feature=> {
            return (
              <SharedInformationCard
                key={feature.getObjectId()}
                feature={feature}
                config={config}
                t={t}
                fields={['reportedremarks']}
                statusField="currvalidationfield"
                template="{feature.pactype}"
              />
            )
          })
        }
        <ShowMore ref={observerTarget} onClick={() => {
          if (showMoreActive) {
            onShowMoreClicked()
          }
        }}>
          {showMoreLoading && <CustomLoader opsColor={opsColor} scale="s"/>}
          {(!queryLoading && !showMoreLoading && !showMoreActive) && t("screen.message.noMoreInformation")}
        </ShowMore>
      </div>
    </LatestUpdatesWrapper>
  );
};

export default SharedInformation;
