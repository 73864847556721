import UpdateComment from "../../../../LatestUpdates/new/UpdateComment";
import {StyledField, StyledTranslationText} from "../../MobilePopup-styled";
import React, {useEffect, useMemo, useState} from "react";
import {LOGIE_API_ENDPOINT} from "../../../../../utils/API";
import {StyledProgress} from "../../../../App/App-styled";

const TranslationIcon = ()=> (
  <svg
    aria-hidden="true"
    className="svg"
    fill="currentColor"
    height="12"
    viewBox="0 0 16 16"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.176 3L13.5 5.058 11.824 3H13v-.5A1.502 1.502 0 0 0 11.5 1H11V0h.5A2.503 2.503 0 0 1 14 2.5V3zM5 9H1.833A.834.834 0 0 1 1 8.167V1.833A.834.834 0 0 1 1.833 1h6.334A.834.834 0 0 1 9 1.833V5h1V1.833A1.835 1.835 0 0 0 8.167 0H1.833A1.835 1.835 0 0 0 0 1.833v6.334A1.835 1.835 0 0 0 1.833 10H5zm1.733-3.956A6.467 6.467 0 0 0 7.179 4H8V3H5.519l.128-.129L4.59 1.813l-.707.707.48.48H2v1h.768a6.176 6.176 0 0 0 1.418 2.414 5.84 5.84 0 0 1-1.79.768l.242.97A6.723 6.723 0 0 0 4.97 7.086c.011.008.025.014.036.022a2.167 2.167 0 0 1 1.727-2.064zM3.83 4h2.284a5.056 5.056 0 0 1-1.148 1.777A4.957 4.957 0 0 1 3.83 4zm10.362 12H7.808A1.81 1.81 0 0 1 6 14.192V7.808A1.81 1.81 0 0 1 7.808 6h6.384A1.81 1.81 0 0 1 16 7.808v6.384A1.81 1.81 0 0 1 14.192 16zM7.808 7A.809.809 0 0 0 7 7.808v6.384a.809.809 0 0 0 .808.808h6.384a.809.809 0 0 0 .808-.808V7.808A.809.809 0 0 0 14.192 7zm5.342 6.94l.899-.439L11 7.261l-3.049 6.24.899.44.46-.941h3.38zM9.798 12L11 9.54 12.202 12zM5 15v1h-.5A2.503 2.503 0 0 1 2 13.5V13H.824L2.5 10.942 4.176 13H3v.5A1.502 1.502 0 0 0 4.5 15z"></path>
  </svg>
)

const PopupField = ({title, value, resetLimit, onClick, textLimit = 90, isPopup, feature, field, i18n, isExpanded, t, style={}}) => {
  const [translation, setTranslation] = useState(null);
  const [isTranslating, setIsTranslating] = useState(false);
  const [showTranslation, setShowTranslation] = useState(false);
  
  useEffect(() => {
    const translatedFields = feature?.layer?.layerConfig?.customPopupOps?.translatedFields;
    
    if (!translatedFields || !Array.isArray(translatedFields) || !translatedFields.includes(field?.fieldName))
      return
    
    const layer = feature?.layer
    setIsTranslating(true)
    
    fetch(LOGIE_API_ENDPOINT + "/getTranslation?" + new URLSearchParams({
      layerId:  layer.originalId,
      objectId:  feature.attributes[layer.objectIdField],
      fieldName:  field.fieldName,
      value:  feature.attributes[field.fieldName],
      lang:  i18n.language === 'en' ? 'en-US' :  i18n.language
    })).then( (response) => {
      if (response.ok)
        return response.text()
    }).then( (data) => {
      setIsTranslating(false)
      const trans = JSON.parse(data)
      
      if (trans.statusCode === 200 && trans.detectedSourceLang !== i18n.language){
        setTranslation(trans.body);
        setShowTranslation(true);
      }
      
    }).catch ((error) => {
      setIsTranslating(false)
      console.error("error occurred fetching translations", error)
    })
  }, [value])
  
  const toggleTranslation = (e) =>{
    e.stopPropagation();
    setShowTranslation(prev=>!prev);
  }
  
  const text = useMemo(()=>{
    if (showTranslation && translation){
      return translation
    } else {
      return value;
    }
  },[value, showTranslation, translation])
  
  const translationText = useMemo(()=>{
    if (showTranslation){
      const text = t('screen.popup.actions.translation.seeOriginal')
      const [autoTranslated, restText] = text.split(')')
      
      return <><span style={{fontStyle:'italic'}}>{autoTranslated})</span><span>&nbsp;{restText}</span></>
    } else {
      return <span>{t('screen.popup.actions.translation.seeTranslated')}</span>
    }
  },[showTranslation, t])
  
  const isArabic = useMemo(()=>{
    // const arabic = /[\u0600-\u06FF]/;
    // return arabic.test(text)
    return i18n.language === 'ar'
  },[])
  
  return (
    <StyledField dir={isArabic ? 'rtl' : ''} isArabic={isArabic} isPopup={isPopup}>
      <p>
        <span>
          {title && <span dir={isArabic ? "rtl" : ""}>{title + ":"}&nbsp;</span>}
          {
            (typeof value !== 'string' || value.includes('target="_blank"')) ?
              <span style={style} dangerouslySetInnerHTML={{__html:value}}></span>
              : (
                <UpdateComment
                  text={text}
                  textLimit={typeof text == 'string' ? isExpanded ? text.length + 1 : 70 : 70}
                  style={style}
                  resetLimit={resetLimit}
                  onClick={onClick}
                />
              )
          }
        </span>
      </p>
      {(isExpanded || isPopup) && <span style={{display: 'flex', direction: isArabic ? 'rtl' : '', flexDirection:'column'}}>
          {isTranslating && <StyledProgress type="indeterminate">retrieving translation</StyledProgress>}
          {translation && <StyledTranslationText onClick={toggleTranslation} isArabic={isArabic}>
          {translationText}</StyledTranslationText>}
        </span>}
    </StyledField>
  );
};

export default PopupField;
