import {renderDomain, renderFieldTitle} from "./helper";
import {svgToBase} from "../components/Icons/layerSvg/clusterAssets";
import {view} from "./API";

const defaultIconSize = 20
const defaultLineSize = 20
const defaultColor = "gray"

const fieldDelimiter = ","

/**
 * Scale presents the zoom level of the map
 * Possible scales: default is the one when user opens the map,
 * Medium is if user zooms in the map in a way that the map becomes 2 times bigger
 * Small is if user zooms in the map in a way that the map becomes 4 times bigger a
 */
const Scale = {
	"default" : 0,
	"medium" : 1,
	"small": 2
}

const RendererType = {
	uniqueValue : "unique-value",
	classBreaks : "class-breaks"
}


export const addSymbology = (layer, config, t, selectedSymbology = undefined) => {
	if (!layer.layerConfig?.symbology)
		return

	let symbology = selectedSymbology;
	if (!symbology) {
		symbology = getLayerSymbology(layer, config)
	}
	
	if (!symbology){
		console.warn("Unknown symbology. Please create the symbology in the layer Symbologies section in the config.json. Layer: " + layer.title )
		return
	}

	const rendererType = (symbology.type && symbology.type === RendererType.classBreaks) ? RendererType.classBreaks : RendererType.uniqueValue
	layer.renderer = createValueRenderer(rendererType, layer, symbology, t)

	const initialScale = view.scale
	let lastUserScale = null
	view.watch("scale", (currentScale) => {
		let mapScale = Scale.default
		if (currentScale < (initialScale / 2) )
			mapScale = Scale.medium

		if (currentScale < (initialScale / 4))
			mapScale = Scale.small

		if (!lastUserScale || lastUserScale !== mapScale){
			lastUserScale = mapScale
			layer.renderer = createValueRenderer(rendererType, layer, symbology, t, mapScale)
		}
	})
}

export const getLayerSymbology = (layer, config) => {
	let symbology;
	if (!Array.isArray(layer.layerConfig?.symbology)) {
		symbology = layer.layerConfig?.symbology;
	} else if (layer.layerConfig?.symbology.length > 0) {
		symbology = layer.layerConfig?.symbology[0];
	}
	
	return config.layerSymbologies[symbology]
}

const createValueRenderer = (rendererType, layer, symbology, t, scale) => {
	if (!scale)
		scale = Scale.default

	let renderer = {
		type: RendererType.uniqueValue,
		fieldDelimiter: fieldDelimiter,
		uniqueValueInfos: [],
		classBreakInfos: [],
		defaultSymbol: createSymbol(symbology, layer, symbology.defaultIcon, symbology.defaultColor, symbology.defaultSize)
	}

	let iconValueDefinitions = 	getIconValueDefinitions(symbology, layer, scale, t)
	renderer.getIconDefinitions = () => { return iconValueDefinitions}
	renderer.getDefaults = () => {return {icon: symbology.defaultIcon, color: symbology.defaultColor, size: symbology.defaultSize}}

	const colorValueDefinitions = getColorValueDefinitions(rendererType, symbology, layer, scale, t)
	renderer.getColorDefinitions = () => { return colorValueDefinitions}

	let valueDefinitions = []
	if (iconValueDefinitions.length > 0 ){
		if (colorValueDefinitions.length > 0) {
			//Merge icon and color definitions
			iconValueDefinitions.forEach((iconVd) =>{
				colorValueDefinitions.forEach((colorVd) =>{
					valueDefinitions.push({icon: iconVd.icon, size: symbology.defaultSize,
						field: iconVd.field.concat(colorVd.field),
						value: iconVd.value + fieldDelimiter + colorVd.value,
						label: `${iconVd.label} ${colorVd.label}`,
						color: colorVd.color})
				})
			})
		} else
			valueDefinitions = iconValueDefinitions
	} else
		valueDefinitions = colorValueDefinitions

	let valueExpression = ""
	valueDefinitions.forEach((vd, idx) => {
		if (rendererType === RendererType.uniqueValue){
			let expression = createValueExpression(symbology, layer, vd.field, vd.value, vd.label, vd.icon, vd.color, vd.size)
			valueExpression += expression[0]
			renderer.uniqueValueInfos.push(expression[1])
		} else if (rendererType === RendererType.classBreaks){
			valueExpression += createBetweenExpression(layer, vd, idx)
			renderer.uniqueValueInfos.push({
				value: idx,
				label: vd.label,
				symbol: createSymbol(symbology, layer, vd.icon, vd.color, vd.size)
			})
		}
	})

	if (valueExpression !== "")
		renderer.valueExpression = `WHEN(${valueExpression}'0')`
	else
		renderer.valueExpression = `1`

	const sizeMap = symbology.sizeMap
	renderer.getSizeDefinitions = () => {
		if (!sizeMap)
			return

		return {field: sizeMap.field, scale: getScale(sizeMap, scale)}
	}
	
	if (!symbology.sizeMap && symbology.defaultSize) {
		const defaultSize = symbology.defaultSize;
		const stops = [
			{ size: defaultSize, value: 9244648 }, //6 0
			{ size: 1, value: 18489297}, //5 1
			{ size: 1, value: 36978595 }, //4 2
			{ size: 1, value: 73957190 }, //3 3
			{ size: 1, value: 147914381 }, //2 4
			{ size: 1, value: 295828763 }, //1 5
		]
		
		stops.forEach((stop, index)=>{
			if (index === 0) return;
			stop.size = stops[index-1].size - (stops[index-1].size * (0.1 + index / 10))
			if (stop.size < 0.5) {
				stop.size = 0.5;
			}
		})
		
		const sizeVV = {
			type: "size",
			valueExpression: "$view.scale",
			stops
		};
		renderer.visualVariables = [sizeVV]
	}
	
	if (sizeMap){
		const sizeField = layer.fields.filter((field) => field.name === sizeMap.field)[0]
		if (!sizeField){
			console.warn("Symbology warning: sizeMap must refer a valid field. Layer: " + layer.title )
		} else {
			renderer.visualVariables = [getSizeVisualVariables(sizeField, getScale(sizeMap, scale), t)]
		}
	}

	return renderer
}

export const getIconValueDefinitions = (symbology, layer, scale, t) => {
	const ret = []
	if (!symbology.iconMap)
		return ret

	const fields = symbology.iconMap.field ? [symbology.iconMap.field] : symbology.iconMap.fields
	const layerFields = filterFields(layer, fields)
	if (!layerFields )
		return ret

	layer.opacity = 1

	const iconMap = getScale(symbology.iconMap, scale)
	iconMap && Object.keys(iconMap).forEach((value) => {
		const iconName = iconMap[value]
		const iconLabel = renderDomain(layerFields, value.split(","), t)
		ret.push({
			icon: iconName, field: layerFields, value: value,
			size: symbology.defaultSize, label: iconLabel, color: symbology.defaultColor
		})
	})

	return ret
}

const getColorValueDefinitions = (rendererType, symbology, layer, scale, t) => {
	const ret = []
	if (!symbology.colorMap)
		return ret

	const fields = symbology.colorMap.field ? [symbology.colorMap.field] : symbology.colorMap.fields
	const layerFields = filterFields(layer, fields)
	if (!layerFields)
		return ret

	const colorMap = getScale(symbology.colorMap, scale)
	if (colorMap) {
		if (rendererType === RendererType.uniqueValue) {
			Object.keys(colorMap).forEach((fieldValue) => {
				const color = colorMap[fieldValue]
				const colorLabel = (fieldValue.includes("-")) ?
					(renderFieldTitle(layerFields[0], t) + ": " + fieldValue) :
					renderDomain(layerFields, fieldValue.split(","), t)

				ret.push({
					field: layerFields,
					value: fieldValue,
					size: symbology.defaultSize,
					label: `${colorLabel}`,
					color: color,
					icon: symbology.defaultIcon
				})
			})
		} else {
			colorMap.forEach((range) => {
				if (layerFields.length > 0)
					console.warn("The symbology of type 'class-breaks' supports only one field. Layer: " + layer.title)

				const colorLabel = renderFieldTitle(layerFields[0], t) + ": " + range.minValue + " - " + range.maxValue

				ret.push({
					field: layerFields, minValue: range.minValue, maxValue: range.maxValue,
					size: symbology.defaultSize, label: `${colorLabel}`,
					color: range.color, icon: symbology.defaultIcon
				})
			})
		}
	}

	return ret
}

const filterFields = (layer, fields) => {
	const layerFields = fields && fields.map((cField) => {
		return layer.fields.filter((field) => field.name === cField)[0]
	}).filter((field) => field)
	if (!layerFields || layerFields.length === 0 ||  layerFields.length !== fields.length){
		console.warn("Symbology warning: symbology must refer valid layer fields. Layer: " + layer.title + " Fields: " + fields )
		return
	}

	return layerFields
}
/**
 * Get the right scale to use:
 * default scale is taken for the original the zoom level,
 * medium scale is taken when the zoom level is bigger than the double of the original one
 * small scale is taken when the zoom level is 4 times bigger than the original one
 */
const getScale = (map, scale) => {
	if (!map)
		return

	if (scale === Scale.small && map.small)
		return map.small

	if ((scale === Scale.medium || scale === Scale.small) && map.medium)
		return map.medium

	return map.default
}
const getSizeVisualVariables = (sizeField, values, t) => {
	const sizes = {
		type: "size",
		field: sizeField.name,
		stops:  [],
	}

	if (!values)
		return sizes

	Object.keys(values).forEach((sizeValue) => {
		sizes.stops.push({
			value: sizeValue,
			size: values[sizeValue],
			label: renderDomain(sizeField, sizeValue, t)
		})
	})
	return sizes
}

const createValueExpression = (symbology, layer, fields, fieldValue, label, iconName, color, size) => {
	let valueExpression = ""
	let fieldValues = fieldValue.split(fieldDelimiter)
	fieldValues.forEach(function (val, i){
		if (i >= fields.length)
			return

		const fieldName = fields[i].name
		if (val.toLowerCase() === "null")
			valueExpression += ` IsEmpty($feature.${fieldName}) &&`
		else if (val.includes("-")) {
			const minMax = fieldValue.split("-")
			valueExpression += ` $feature.${fieldName} >= ${minMax[0]} && $feature.${fieldName} < ${minMax[1]} &&`
		} else if (val !== "*"){
			valueExpression += (fields[i].type === "string") ? ` $feature.${fieldName} == '${val}' &&` : ` $feature.${fieldName} == ${val} &&`
		}
	})

	if (valueExpression.endsWith("&&"))
		valueExpression = valueExpression.slice(0,-2).trim()

	return [
		`${valueExpression},'${fieldValue}',`,
		{
			value: fieldValue,
			label: label,
			symbol: createSymbol(symbology, layer, iconName, color, size)
		}
	]
}

/**
 * Create definition expression for mix and max values based on a specific field value
 */
const createBetweenExpression = (layer, vd, index) => {
	let valueExpressionElements = []
	if (vd.minValue !== undefined)
		valueExpressionElements.push(` $feature.${vd.field[0].name} >= ${vd.minValue} `)

	if (vd.maxValue !== undefined)
		valueExpressionElements.push(` $feature.${vd.field[0].name} < ${vd.maxValue} `)

	const valueExpression = valueExpressionConcat(valueExpressionElements, "&&")
	return `${valueExpression},'${index}',`
}

const valueExpressionConcat = (elements, separator) => {
	let res = ""
	for (const key in elements) {
		const val = elements[key]

		if (!val || val.trim().length === 0)
			continue

		if (res.length > 0)
			res += ` ${separator} `

		res += val
	}

	return res
}

export const createSymbol = (symbology, layer, iconName, color, size) => {
	if (layer.geometryType === "polyline" || layer.geometryType === "line"){
		return {
			type: "simple-line",
			color: color ? color : defaultColor,
			width: size ? size : defaultLineSize,
			style: "solid"
		}
	} else if (layer.geometryType === "point" )
		return {
			type: "picture-marker",
			url: svgToBase(iconName, color, false),
			height: size ? size : defaultIconSize,
			width: size ? size : defaultIconSize
		}
	else if (layer.geometryType === "polygon" ) {
		const outline = {
			width: symbology.defaultBorderSize !== undefined ? symbology.defaultBorderSize : 1,
			color: symbology.defaultBorderColor? symbology.defaultBorderColor : "black"
		}

		return {
			type: "simple-fill",
			color: color,
			style: "solid",
			outline: outline
		}
	}
}
