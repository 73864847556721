import styled from "styled-components";

export const LegendHeader = styled.div`
    display: flex;
    gap: 0.5em;
    font-style: normal;
    font-size: 17px;
    line-height: 120%;
    color: #031C2D;
  
    ${({isEmbed})=> isEmbed ? `
      .legendBox__title {
        font-size: 11px;
        line-height: normal;
        font-weight:500;
      }
    
      .expandButton {
        font-size: 10px;
      }
    ` : ''};

  @media screen and (max-width: 768px){
    align-items: center;
    justify-content: center;
    gap: 4px;
    
    .legendBox__title {
      font-size: 14px;
      font-weight: 500;
    }
  }
}

`
export const StyledContainer = styled.div`
    line-height: 1.5em;
    font-size: xx-small;
  	grid-auto-flow: dense;
    gap: 0.5em;
    margin-top: 0.5em;
`
export const LayerGroupPanel = styled.div`
  	padding: 0.25em;
    @media (min-width: 768px) {
      grid-column: span ${props => `${props.colspan}`};
      grid-row: span ${props => `${props.rowspan}`};
    }
`

export const LayerTitle = styled.div`
    color: grey;
    font-size: ${({isEmbed})=>isEmbed ? '10px' : '1.2em'} !important;
    font-weight: 400 !important;
    text-align: left !important;
    padding-bottom: 0.5em;
`

export const LayerPanel = styled.div`
  	display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
 `

export const LayerIconPanel = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.125rem;
`

export const LayerColorPanel = styled.div`
  display: flex;
  flex-direction: column;
`

export const LayerColorLine = styled.div`
  display: flex;
  gap: 0.25em;
  align-items: center;
`

export const SymbolLine = styled(LayerColorLine)`
  display: flex;
`

export const SymbolTitle = styled.div`
  text-align: left;
`

export const SymbolCont = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  img {
    max-width: 100%;
  }

`

export const Symbol = styled.img`
`

export const ColorCube = styled.div`
  flex-shrink: 0;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  background: ${props => props.color ? `${props.color}` : 'gray'}
`

export const ColorLine = styled.div`
  width: 20px;
  flex-shrink: 0;
  height: ${props => props.size ? `${props.size}` : '5'}px;
  border-radius: 3px;
  background: ${props => props.color ? `${props.color}` : 'gray'}
`

export const ColorPolygon = styled.div`
  width: 0;
  height: 15px;
  border-top: 7px solid transparent;
  filter: drop-shadow(1px 1px 1px rgba(0,0,0,0.2));
  border-left: 15px solid ${props => props.color ? `${props.color}` : 'gray'}
`
