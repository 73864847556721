import React, {useCallback, useMemo} from "react";
import {createSymbol, getLayerSymbology} from "../../utils/symbologies";
import {iconNameToIcon, svgToBase} from "./layerSvg/clusterAssets";
import {LayerIconWrapper} from "../ManualWidgets/ManualWidget-styled";
import {getLayerTitle} from "../../esri/custom-popup-content";

const LayerIcon = ({config, layer, t, fill, opsColor, width = 40, height = 40, selected, textColor = '#fff'}) => {
  const getLayerDefaultSymbol = useCallback((layer)=>{
    const symbology = getLayerSymbology(layer, config);
    
    if (!symbology) {
      
      if (layer.renderer && layer.renderer.defaultSymbol) {
        return {
          type: 'picture-marker',
          url: layer.renderer.defaultSymbol.url
        }
      }
      
      return '' ;
    }
    const symbol = createSymbol(symbology, layer, symbology.defaultIcon, symbology.defaultColor, symbology.defaultSize)
    
    if (symbol.type === 'picture-marker') {
      return {...symbol, icon: iconNameToIcon(symbology.defaultIcon)};
    }
    
    if (layer.layerConfig?.titleLabel === 'roads') {
      return {...symbol, icon: iconNameToIcon(layer.layerConfig?.titleLabel)}
    }
    
    if (symbol.type === 'simple-fill'){
      return symbol;
    }
    
    return {
      type: symbol.type,
      url: svgToBase(symbology.defaultIcon, symbology.defaultColor),
      icon: iconNameToIcon(symbology.defaultIcon)
    }
  },[config])
  
  const symbol = useMemo(()=>{
    return getLayerDefaultSymbol(layer);
  },[layer])
  
  const layerTitle = useMemo(()=>getLayerTitle(layer, t),[layer,t])
  return (
    symbol ? <>
      {
        symbol.type === 'simple-fill' && (<div style={{
          width: width - 10,
          height: height - 10,
          display:'flex',
          alignItems:'center',
          justifyContent:'center',
          color: textColor,
          fontSize: 20
        }}>
          <span>{layerTitle.length > 0 ? layerTitle[0] : ''}</span>
        </div>)
      }
      {
        (symbol.icon && layer.layerConfig?.titleLabel !== 'roadsSituational' && symbol.type !== 'simple-fill') && (
          <LayerIconWrapper
            width={width}
            height={height}
            opsColor={opsColor}
            fill={fill}
            selected={selected}
            dangerouslySetInnerHTML={{__html:symbol.icon}}
          />
        )
      }
  
      {
        layer.layerConfig?.titleLabel === 'roadsSituational' && (
          <svg stroke="currentColor" fill={fill} strokeWidth="0" viewBox="0 0 24 24" height={height} width={width} xmlns="http://www.w3.org/2000/svg"><g id="Route"><path d="M21.792,17.086c-.58-.58-1.16-1.17-1.75-1.75-.08-.08-.16-.17-.25-.25a.492.492,0,0,0-.7,0,.5.5,0,0,0,0,.71l1.14,1.14H9.282a2.22,2.22,0,0,1,0-4.44h3a3.215,3.215,0,1,0,0-6.43H7.012a2.5,2.5,0,1,0,0,1h5.27a2.215,2.215,0,1,1,0,4.43h-3a3.22,3.22,0,1,0,0,6.44h10.96l-.9.9c-.09.08-.17.17-.25.25a.5.5,0,0,0,0,.71.511.511,0,0,0,.7,0l1.75-1.75.25-.25A.5.5,0,0,0,21.792,17.086ZM4.562,8.066a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,4.562,8.066Z"></path></g></svg>
        )
      }
    </> : (
      <div style={{
        width: width - 10,
        height: height - 10,
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        color: textColor,
        fontSize: 24
      }}>
        <span>{layerTitle.length > 0 ? layerTitle[0] : ''}</span>
      </div>
    )
  );
};

export default LayerIcon;
