import React, {useCallback, useEffect, useMemo, useRef, useState, useContext} from "react";
import {view} from "../../../utils/API";
import {loadModules} from "esri-loader";
import {
  LatestUpdatesWrapper,
} from "./LatestUpdates-styled";
import {useSelector} from "react-redux";
import LatestUpdateHeader from "./LatestUpdateHeader";
import { recent16,} from "@esri/calcite-ui-icons";
import {ShowMore} from "../../ReportManager/ReportManager-styled";
import {CustomLoader, CustomSwitch} from "../../App/App-styled";
import useWindowSize from "../../../hooks/useWindowSize";
import useInView from "../../../hooks/useInView";
import usePullToRefresh from "../../../hooks/usePoolToRefresh";
import useDragSidebar from "../../../hooks/useDragSidebar";
import {ConfigContext} from "../../../utils/ConfigContext";
import {useTranslation} from "react-i18next";
import SharedInformationCard from "../../SharedInformation/SharedInformationCard/SharedInformationCard";
import {getOpsColor} from "../../../utils/helper";

const defaultWidth = 284;
const mobileOffset = 35;

export const LatestUpdatesIcon = ({color, ...props})=>{
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="16"
         height="16"
         viewBox="0 0 16 16"
         fill={color}
        {...props}
    >
      <path d={recent16} />
    </svg>
  )
}

const LatestUpdates = ({processTab, searchText = ''}) => {
  const {showLatestUpdates, activeModule, mobileReducer} = useSelector(state=>state)
  const {config} = useContext(ConfigContext);
  const {t} = useTranslation("common")
  
  const title = t('screen.widget.LatestUpdates.title')
  const [lastDate, setLastDate] = useState(new Date());
  const {width} = useWindowSize();
  
  const isMobile = useMemo(()=>{
    return width <= 768 || mobileReducer.isMobileApp;
  },[width, mobileReducer.isMobileApp])
  
  let [offset, setOffset] = useState(20);
  const ref = useRef();
  /**
   * This is only a trigger to re-render the page. every time we load new data (feature or attachment)
   * we change the values of this trigger
   */
  let [layersLoaded, setLayersLoaded] = useState(0);
  const [loading, setLoading] = useState(false);
  const [extentLoading, setExtentLoading] = useState(false);
  const [extent, setExtent] = useState(undefined);
  const extentListener = useRef();
  const extentRef = useRef(extent);
  const watchHandlers = useRef([]);
  
  const refreshRef = useRef();
  const loaderRef = useRef();
  usePullToRefresh(refreshRef, ()=>{
    view.map.layers.forEach((layer) => {
      processLayer(layer)
    })
  }, loaderRef)
  
  const isShownInModule = useMemo(()=>{
    if (!config) return false;
    const module = config.modules[activeModule] ?? config.opDefaults.modules[activeModule];
    return module?.isLatestUpdatesShown ?? true;
  },[activeModule])
  
  useEffect(() => {
    if (!isShownInModule) {
      watchHandlers.current.forEach(h=>h.remove());
      watchHandlers.current = [];
      return;
    }
    
    const layersChangeHandler = view.map.layers.on("change", (event) => {
      if (event.added)
        event.added.forEach((layer) => {
          processLayer(layer)
          const editHandler = layer.on("edits", (event) => {
            processLayer(layer)
          })
          const refreshHandler = layer.on("refresh", () => {
            processLayer(layer)
          })
          const visibleHandler = layer.watch("visible", () => {
            setLayersLoaded(layersLoaded++)
          })
          watchHandlers.current.push(editHandler, refreshHandler, visibleHandler)
        })
    })
    watchHandlers.current.push(layersChangeHandler)
    
    view.map.layers.forEach((layer) => {
      processLayer(layer)
      
      //Update page when edit or refresh events occur:
      const editHandler = layer.on("edits", (event) => {
        processLayer(layer)
      })
      const refreshHandler = layer.on("refresh", () => {
        processLayer(layer)
      })
      const visibleHandler = layer.watch("visible", () => {
        setLayersLoaded(layersLoaded++)
      })
      watchHandlers.current.push(editHandler, refreshHandler, visibleHandler)
    })
    
    return ()=>{
      watchHandlers.current.forEach(h=>h.remove());
    }
    
  }, [isShownInModule])
  
  useEffect(()=>{
    loadModules(["esri/core/reactiveUtils"]).then(([reactiveUtils]) =>{
      if (extent) {
        const stationary = reactiveUtils.when(() => !!view?.stationary, ()=>{
          if (!extent) return;
          setExtentLoading(true);
          view.map.layers.forEach(layer=>{
            processLayer(layer);
          })
        },{initial:true})
        extentListener.current = stationary;
      } else {
        extentListener.current?.remove();
        setExtentLoading(true);
        view.map.layers.forEach(layer=>{
          processLayer(layer);
        })
      }
    })
  },[extent])
  
  let timer;
  const disableExtentLoading = useCallback(()=>{
    clearTimeout(timer);
    timer = setTimeout(()=>{
      setExtentLoading(false)
    },500)
  },[])
  
  const processLayer = useCallback ((layer) => {
    setLastDate(new Date());
    layer.load().then((layer) => {
      if (layer.type !== "feature" || !layer.layerConfig || !layer.layerConfig?.isShownInLatestUpdates || !layer.editFieldsInfo?.editDateField){
        layer.lastEditedFeaturesQueryReady = true
        setLayersLoaded(layersLoaded++)
        disableExtentLoading();
        return
      }
      
      const editDateFieldName = layer.editFieldsInfo?.editDateField
      const editDateField = layer.fields.filter((field) => field.name === editDateFieldName)[0]

      if (!editDateField) {
        console.warn("Layer: " + layer.title + " defines a missing editDateField: " + editDateFieldName)
        layer.lastEditedFeaturesQueryReady = true
        setLayersLoaded(layersLoaded++)
        disableExtentLoading();
        return
      }
      
      queryLayer(layer, editDateFieldName)
      layer.watch("definitionExpression", () => {
        setExtentLoading(true);
        queryLayer(layer, editDateFieldName)
      })
    })
  })
  
  /**
   * Query a layer for the 5 most (or least) recently edited features and their attachments
   * the results will be saved in the layer.lastEditedFeatures property
   */
  const queryLayer = (layer, editDateFieldName) => {
    loadModules(["esri/rest/support/Query"]).then(([Query]) => {
      const query = new Query()
      query.outFields = ["*"]
      query.where = editDateFieldName + " is not null"
      if (layer.definitionExpression)
        query.where += " and (" + layer.definitionExpression + ")"
      
      query.orderByFields = [editDateFieldName + " DESC"]
      query.returnGeometry = true
      if (extentRef.current){
        query.geometry = view.extent
      }
      
      layer.queryFeatures(query).then((lastEditedFeatures) => {
        const lastEditedFiltered = lastEditedFeatures.features.filter((ftr) =>
          ftr.attributes[editDateFieldName] && ftr.attributes[editDateFieldName] != null
        )
        
        if (isEqual(layer.lastEditedFeatures, lastEditedFiltered, layer, editDateFieldName)){
          layer.lastEditedFeaturesQueryReady = true
          disableExtentLoading();
          return
        }

        layer.lastEditedFeatures = lastEditedFiltered
        if (!layer.lastEditedFeatures || layer.lastEditedFeatures.length === 0 || !layer.capabilities.operations.supportsQueryAttachments){
          layer.lastEditedFeaturesQueryReady = true
          setLayersLoaded(layersLoaded++)
          disableExtentLoading();
          return
        }

        setLayersLoaded(layersLoaded++)
        queryLayerAttachments(layer)
        disableExtentLoading();
      }).catch(err=>{
        console.log(err);
      })
    })
  }
  
  const isEqual = (resA, resB, layer, editDateFieldName) => {
    
    const lenA = !resA ? 0 : resA.length
    const lenB = !resB ? 0 : resB.length
    if (lenA !== lenB)
      return false
    
    const oidField = layer.objectIdField
    resA?.forEach((fa, idx) => {
      const fb = resB[idx]
      if (fa.attributes[editDateFieldName] !== fb.attributes[editDateFieldName] || fa.attributes[oidField] !== fb.attributes[oidField])
        return false
    })
    
    return true
  }
  /**
   * Query the layer for attachments of the most (or least) recently edited features.
   * The features to query for are already stored in the layer.lastEditedFeatures property
   */
  const queryLayerAttachments = (layer) => {
    const oidField = layer.objectIdField
    const objectIds = layer.lastEditedFeatures.map((feature) => feature.attributes[oidField])
    
    layer.queryAttachments({objectIds: objectIds}).then((response) => {
      response && Object.keys(response).forEach((oid) => {
        // Each object might have different attachments
        const objectAtts = response[oid]
        
        layer.lastEditedFeatures.forEach((feature) => {
          // Save attachments into the layer features array, assigned to the correct feature
          if (feature.attributes[oidField].toString() === oid) {
            feature.attachments = objectAtts
          }
        })
      })
      
      layer.lastEditedFeaturesQueryReady = true
      setLayersLoaded(layersLoaded++)
    })
  }
  
  let timeout;
  const onShowMoreClicked = () => {
    
    if (!showMoreActive || timeout) return;
    setLoading(true);
    timeout = setTimeout(()=>{
      setOffset(prev=>prev + 20)
      timeout = null;
      setLoading(false);
    },350)
  }
  
  const getFeatureEditDate = (feature) => {
    const editDateFieldName = feature.layer.editFieldsInfo?.editDateField
    if (!editDateFieldName)
      return
    
    return feature.attributes[editDateFieldName]
  }
  
  const compareFeatures = (a, b) => {
    const editDateA = getFeatureEditDate(a)
    if (!editDateA)
      return 1
    
    
    const editDateB = getFeatureEditDate(b)
    if (!editDateB)
      return 1
    
    return (editDateA > editDateB) ? -1 : 1
  }
  let showMoreActive = false;
  const queriesInProgress = view.map.layers.filter((layer) => !layer.lastEditedFeaturesQueryReady).length;
  
  let featuresToShow = [];
  view.map.layers.forEach((layer) => {
    if (layer.visible && layer.lastEditedFeatures && isShownInModule) {
      const featuresShownOnUpdate = layer.lastEditedFeatures.filter((feat)=> feat.attributes.shownupdates === undefined || feat.attributes.shownupdates === 1)
      featuresToShow = featuresToShow.concat(featuresShownOnUpdate)
    }
  })
  
  featuresToShow = featuresToShow.sort((a,b) => compareFeatures(a, b))
  
  if (searchText.length > 0){
    featuresToShow = featuresToShow.filter(feat=>{
      const layer = feat.sourceLayer ?? feat.layer;
      if (layer){
        const titleTemplate = layer?.layerConfig?.titleTemplate;
        const searchField = titleTemplate?.substring(1, titleTemplate.length-1)?.replace('feature.', '');
        
        if (searchField && feat.attributes[searchField] && typeof feat.attributes[searchField] === 'string') {
          const lowerValue = feat.attributes[searchField].toLowerCase();
          const lowerText = searchText.toLowerCase();
          return lowerValue.includes(lowerText);
        }
        
        return false;
      }
      return false;
    });
  } else {
    if (featuresToShow.length > offset){
      featuresToShow = featuresToShow.slice(0, offset)
      if (queriesInProgress === 0)
        showMoreActive = true
    }
  }
  
  const mounted = useRef(false);
  
  useEffect(() => {
    if (featuresToShow.length > 0 && !mounted.current) {
      const viewDiv = document.querySelector('#viewDiv');
      mounted.current = true;
      viewDiv.style.transition = 'all 0.5s';
      // if (isMobile) {
      //   viewDiv.style.height = `calc(100% - ${mobileOffset}px)`;
      // }
      if (processTab){
        processTab({tab: "latestUpdates", visible: true})
      }
    }
    
    if (featuresToShow.length === 0 && mounted.current) {
      mounted.current = false;
      // const viewDiv = document.querySelector('#viewDiv');
      if (isMobile) {
        // viewDiv.style.height = ``;
      } else {
        if (ref.current) {
          ref.current.style.flexBasis = '';
        }
      }
      if (processTab && !extentRef.current){
        processTab({tab: "latestUpdates", visible: false})
      }
    }
  },[featuresToShow])
  
  const observerTarget = useRef(null);
  useInView(observerTarget, onShowMoreClicked, [showMoreActive])
  
  const handleSwitchChange = useCallback((e)=>{
    const checked = e.target.checked || undefined;
    setExtent(checked);
    extentRef.current = checked
  },[])
  
  const dateLabel = t('screen.widget.LatestUpdates.reportedOn');
  const opsColor = useMemo(()=> getOpsColor(config),[config, activeModule])
  return (
    <LatestUpdatesWrapper className={!isMobile && featuresToShow.length > 0 ? 'onboarding-latest-updates' : ''}>
      <LatestUpdateHeader
        config={config}
        updateDate={lastDate}
        queriesInProgress={queriesInProgress > 0 ? queriesInProgress : extentLoading ? 1 : 0}
      >
        {!isMobile && <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingRight: "4px"
        }}>
          <div style={{display: "flex", alignItems: "center"}}>
            <span style={{fontSize: 12, lineHeight: 1, marginRight: 6, color: "#393738"}}>{t('screen.widget.common.filterByMapExtent')}</span>
            <CustomSwitch opsColor={opsColor} checked={extent} onCalciteSwitchChange={handleSwitchChange}/>
          </div>
        </div>}
      </LatestUpdateHeader>
      <div ref={loaderRef} id="refresh" style={{opacity:0, position:'absolute', top: isMobile ? 14 : 54, left:'50%', transform:'translateX(-50%)'}}>
        <CustomLoader opsColor={opsColor} scale="s" />
      </div>
      <div
        ref={refreshRef}
        style={{
          overflow:'auto',
          height:'100%',
          display:'flex',
          gap: isMobile ? 6 : 0,
          flexDirection:'column',
          padding: isMobile ? '0px 0px 12px 0px' : '22px 0px 12px 0px',
        }}>
        {
          featuresToShow.map(feature=> {
            const layer = feature.sourceLayer || feature.layer
            const titleTemplate = layer.layerConfig?.titleTemplate;
            return (
              <SharedInformationCard
                key={feature.getObjectId()}
                feature={feature}
                t={t}
                config={config}
                statusField="currstatus_operational"
                template={titleTemplate}
                dateLabel={dateLabel}
                mobileStyles={isMobile}
                style={{
                  margin: isMobile ? 0 : undefined,
                  border: isMobile ? 'none' : undefined
                }}
              />
            )
          })
        }
        <ShowMore ref={observerTarget} onClick={() =>{
          if (showMoreActive) {
            onShowMoreClicked()
          }
        }}>
          {loading && <CustomLoader opsColor={opsColor} scale="s"/>}
          {(!loading && !showMoreActive) && t('screen.message.noMoreInformation')}
        </ShowMore>
      </div>
    </LatestUpdatesWrapper>
  );
};

export default LatestUpdates;
