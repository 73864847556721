import {DragAndDropGraphics} from "./DragAndDropGraphics";
import {getOpsColor} from "../../utils/helper";

export class CommentBoxes extends DragAndDropGraphics {

    createSvgSymbol = (feature) => {
        const padding = 5
        const boxOpts = this.layer.layerConfig.commentBoxOps
        const title = feature.attributes[boxOpts.titleField]
        const comment = feature.attributes[boxOpts.commentField]

        if (!comment && !title)
            return
        
        const stringifiedTitle = String(title) || '';
        const stringifiedComment = String(comment) || '';
        
        if (!stringifiedTitle.trim() && !stringifiedComment.trim()) {
            return;
        }

        const svgContainer = this.createNsElement("svg")
        svgContainer.style.backgroundColor = "rgba(255, 255, 255, 0.5)"

        const textEl = this.createNsElement("text")
        svgContainer.append(textEl)

        let height = 0, maxWidth = 0
        if (title) {
            height += 9
            textEl.append(this.getTextEl('<tspan style="font-weight:bold">' + title + '</tspan>', 8, height))
            maxWidth = Math.max(maxWidth, title.length * 4.5 + 2 * padding)
        }

        this.splitCommentToLines(comment).forEach((line) => {
            height += 9
            textEl.append(this.getTextEl(line, 8, height))
            maxWidth = Math.max(maxWidth, line.length * 4)
        })

        maxWidth += padding
        height += padding

        const rectEl = this.getRectEl(maxWidth, height)
        svgContainer.insertBefore(rectEl, textEl)

        this.setElementWidthHeight(svgContainer, maxWidth, height)
        return { type: "picture-marker", height: height, width: maxWidth,
            url: "data:image/svg+xml;base64," + btoa(unescape(encodeURIComponent(new XMLSerializer().serializeToString(svgContainer))))
        }
    }

    /**
     * Split comment into lines
     */
    splitCommentToLines = (comment)=> {
        const maxCommentLineLength = 45
        const lines = []
        let currentLine = ''
        comment && comment.split(' ').forEach((word) => {
            if (currentLine.length + word.length > maxCommentLineLength){
                lines.push(currentLine)
                currentLine = ""
            }
            currentLine += word + " "
        })

        if (currentLine.trim().length > 0)
            lines.push(currentLine)

        return lines
    }

    getRectEl = (width, height) => {
        const rectEl = document.createElementNS("http://www.w3.org/2000/svg", "rect")
        rectEl.style.fill = "white"
        rectEl.style.stroke = getOpsColor(this.config);
        rectEl.style.strokeWidth = 1
        rectEl.setAttribute("width", width)
        rectEl.setAttribute("height", height)
        return rectEl
    }

    getTextEl = (text, fontSize, height) => {
        const tspan = document.createElementNS("http://www.w3.org/2000/svg", "tspan")
        tspan.setAttribute("x", "50%")
        tspan.setAttribute("y", height)
        tspan.style.fontSize = fontSize + "px"
        tspan.style.fontFamily = "'Open Sans', sans-serif"
        tspan.style.textAnchor = "middle"
        tspan.innerHTML = text.trim()

        return tspan
    }
}
