import {forwardRef, useCallback, useContext, useEffect, useRef} from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-balham.css";
import {view} from "../../../utils/API";
import useCustomSnackbar from "../../../hooks/useCustomSnackbar";
import {useTranslation} from "react-i18next";
import {ConfigContext} from "../../../utils/ConfigContext";
import LayerTable from "../../LayerTable/LayerTable"; // Optional Theme applied to the grid

const formatToDate = (time) =>{
  const date = new Date(time)
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // month is zero-based
  let dd = date.getDate();
  
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  
  const formatted = yyyy + '-' + mm + '-' + dd;
  return formatted;
}

const TableGrid = forwardRef(({row, columns, layer, features, setGridApi}, ref) => {
  const [openSnackbar] = useCustomSnackbar()
  const {t} = useTranslation('common');
  const {config} = useContext(ConfigContext);
  
  const highlightedFeatures = useRef({});
  
  useEffect(()=>{
    return () =>{
      
      Object.values(highlightedFeatures.current).forEach(highlightSelect=>{
        highlightSelect.remove();
      })
    }
  },[])
  
  const onCellValueChanged = useCallback((event)=>{
    const {colDef, data, newValue, api, node, oldValue} = event;
    const objecIdField = layer.objectIdField;
    const feature = features.find(feat=>feat.attributes[objecIdField] === data[objecIdField]);
    
    const newData = data;
    
    if (colDef.codedValues) {
      const codedValue = colDef.codedValues.find(cv=>cv.title === newValue);
      feature.attributes[colDef.field] = codedValue.code
    } if (colDef.cellDataType === 'date'){
      
      if (newValue) {
        const formattedValue = newValue.getTime(); // - (newValue.getTimezoneOffset() * 60000);
        feature.attributes[colDef.field] = formattedValue;
        newData[colDef.field] = formatToDate(newValue);
      } else {
        newData[colDef.field] = oldValue;
      }
      
      node.updateData(newData)
    } else {
      feature.attributes[colDef.field] = newValue === 'NULL' ? null : newValue;
    }
    console.log(feature)
    const clone = feature.clone();
    clone.geometry = null;
    layer.applyEdits({
      updateFeatures: [clone]
    }).then(res=>{}).catch(err=>{
      newData[colDef.field] = oldValue
      node.updateData(newData)
      openSnackbar(t('screen.message.error'), 5000)
    })
  },[features, layer, config])
  
  const cellClicked = useCallback((event)=>{
    const {colDef, data, node, value} = event;
    if (colDef.field === 'featureSelect') {
      const objecIdField = layer.objectIdField;
      const feature = data.feature;
      const featureObjectId = feature.attributes[objecIdField];
      const newData = data;
      
      if (!value) {
        view.whenLayerView(layer).then(layerView=>{
          highlightedFeatures.current = {
            ...highlightedFeatures.current,
            [featureObjectId]: layerView.highlight(feature)
          };
          newData[colDef.field] = true;
          node.updateData(newData)
        })
      } else {
        const {[featureObjectId]: highlightSelect, ...rest} = highlightedFeatures.current;
        highlightSelect?.remove()
        highlightedFeatures.current = rest
        newData[colDef.field] = false;
        node.updateData(newData)
      }
    }
  },[layer])
  
  return (
    <LayerTable
      layer={layer}
      columns={columns}
      rows={row}
      setGridApi={setGridApi}
      onCellClicked={cellClicked}
      onCellValueChanged={onCellValueChanged}
    />
  )
})

export default TableGrid;
