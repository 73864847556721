import React, {useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {loadModules} from "esri-loader";
import {web24} from "@esri/calcite-ui-icons";
import Dropdown, {DropdownMenu, DropdownMenuItem} from "../../../Dropdown";
import {isWidgetDisplayed} from "../../../../esri/widgets/expandUtils";
import {SectionItem} from "../../Header-styled";
import {GlobeIcon} from "../../../Icons";
import WidgetButton from "../WidgetButton";
import {useSelector} from "react-redux";
import {getOpsColor} from "../../../../utils/helper";

const languages = {
  "ar": {
    title: "عربى",
    displayCode: 'ar'
  },
  "zh-cn": {
    title:"中国人",
    displayCode: 'zh'
  },
  "en": {
    title: "English",
    displayCode: 'en'
  },
  "fr": {
    title: "Français",
    displayCode: 'fr'
  },
  "es": {
    title: "Español",
    displayCode: "es"
  },
  "ru": {
    title: "Pусский",
    displayCode: 'ru'
  },
  "tr": {
    title: "Türkçe",
    displayCode: 'tr'
  },
  "pt": {
    title: "Português",
    displayCode:'pt'
  }
}

const LanguageDropdown = ({config, className, isModules}) => {
  const {t, i18n} = useTranslation('common')
  const [show, setShow] = useState(false);
  const {activeModule} = useSelector(state=>state);
  
  const hideLanguageSelection = useMemo(()=>{
    return !isWidgetDisplayed(config, 'LanguageSwitcher') || !(config.supportedLngs && config.supportedLngs.length > 1)
  },[config])
  
  if (hideLanguageSelection) return null;
  
  const opsColor = useMemo(()=> getOpsColor(config),[config])
  
  return (
    <Dropdown
      setShow={setShow}
      show={show}
      leftOffset={0.5}
      top={8}
    >
      <WidgetButton
        title={t('screen.widget.LanguageSwitcher.name')}
        className={className}>
        <GlobeIcon />
      </WidgetButton>
      <DropdownMenu>
        {
          config.supportedLngs.map(lang=>(
            <DropdownMenuItem
              key={lang}
              selected={i18n.language === lang}
              opsColor={opsColor}
              onClick={()=>{
                const languageConfig = {
                  showModules: isModules,
                  languageModule: activeModule
                }
                localStorage.setItem('languageConfig', JSON.stringify(languageConfig));
                i18n.changeLanguage(lang, () => {
                  loadModules(["esri/intl"]).then(([intl]) => {
                    if (lang === 'zh-cn') {
                      intl.setLocale('zh-CN');
                    } else {
                      intl.setLocale(lang);
                    }
                  })
                }).then(()=>window.location.reload())
                setShow(false);
              }}
            >
              <span
                style={{
                  fontWeight:600,
                  color: opsColor
                }}>{languages[lang].displayCode?.toUpperCase()}</span>
              <span>{languages[lang].title}</span>
            </DropdownMenuItem>
          ))
        }
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageDropdown;
