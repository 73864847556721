import Dropdown, {DropdownBody} from "../../Dropdown";
import {filter16} from "@esri/calcite-ui-icons";
import {Tag} from "../SharedInformationCard/SharedInformationCard-styled";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {FilterDot, FilterLabel} from "./DropdownFilter-styled";
import useFilters from "../../../hooks/useFilters";
import {useSelector} from "react-redux";

const DropdownFilter = ({config, options, fieldName = 'currvalidationfield', onChange, selected}) => {
  const [show, setShow] = useState(false);
  const {activeModule} = useSelector(state=>state);
  const {filters, setFilters} = useFilters({isActive:false, config});
  
  const onFilterChange = useCallback((e, value) => {
    if (onChange){
      onChange(e,value);
    } else {
      let _filters = JSON.parse(JSON.stringify(filters))
      let filterArray = _filters[activeModule][fieldName] || [];
      if (filterArray.includes(value)) {
        filterArray = filterArray.filter(option=>option !== value);
      } else {
        filterArray.push(value);
      }
  
      _filters[activeModule][fieldName] = filterArray;
      setFilters(_filters)
    }
  },[setFilters, filters, activeModule])
  
  const selectedFilters = useMemo(()=> selected ? selected : filters[activeModule][fieldName] || [],[activeModule, filters, selected])
  
  return (
    <Dropdown
      setShow={setShow}
      show={show}
    >
      <div>
        <button
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            display:'flex',
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg"
               width="16"
               height="16"
               viewBox="0 0 16 16"
               fill="#858383"
          >
            <path d={filter16} />
          </svg>
        </button>
      </div>
      <DropdownBody style={{alignItems:'flex-start'}}>
        <div
          style={{
            display:'flex',
            gap: 6,
            flexDirection:'column',
            padding: 4
          }}>
          {
            options.map(option=>(
              <FilterLabel
                key={option.code}
              >
                <input
                  name={option.code}
                  onChange={(e)=>onFilterChange(e, option.code)} type="checkbox"
                  checked={selectedFilters.includes(option.code)}
                />
                <Tag bg={option.color} status={option.code} />
                <p>{option.name}</p>
              </FilterLabel>
            ))
          }
        </div>
      </DropdownBody>
    </Dropdown>
  );
};

export default DropdownFilter;
