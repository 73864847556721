import styled from "styled-components";

export const FeatureHeadingWrapper = styled.div`
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;

  img {
    background-size: 100%;
    width: 26px;
    height: 26px;
    vertical-align: middle;
    border-radius: 100%;
  }
  span {
    font-weight: 600;
  }
`

export const FeatureTitle = styled.div`
  flex: 1;
  width: calc(100% - 38px);

  ${({isPopup})=> isPopup ? `
    display:flex;
    align-items:center;
  ` : ''}
  
  p {
    font-weight: 400;
    color: #000;
    font-size: ${({isPopup})=> isPopup ? '22px' : '28px'} !important;
    margin: 0;
    line-height: normal;

    a {
      padding:0 2px;
      margin-left:2px;
      transition:all 0.3s;

      :hover {
        background: rgba(60,64,67,0.04);
      }
    }
  }
`

export const FeatureDescription = styled.span`
  font-size: 10px;
  color: #999;
  font-weight: 500;
  display: inline-block;
`
